import React from "react"

import Layout from "../components/archive/layout"
import SEO from "../components/archive/seo"
import Grid from "../components/archive/lib/grid"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Grid>
      <Grid.Row>
        <Grid.Col>
          <h1>NOT FOUND</h1>
          <p>
            Looks like there's a player missing from the field. Please go back
            while we try to find them.
          </p>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  </Layout>
)

export default NotFoundPage
